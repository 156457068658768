import { createReducer } from "@reduxjs/toolkit";

import {
    API_KEYS_HIDE_MODAL,
    API_KEYS_HIDE_RESULT_MODAL,
    API_KEYS_SHOW_MODAL,
    createApiKey,
    deleteApiKey,
    getApiKeys,
} from "../actions/apiKeys";

export interface IApiKey {
    id: number;
    userId: number;
    organizationId: number;
    apiKey?: string;
    description: string;
    createdAt: number;
    accessedAt?: number;
}

export interface IApiKeyState {
    apiKeys: IApiKey[];
    showCreateModal: boolean;
    showCreateResultModal: boolean;
    createdApiKey?: IApiKey;
}

const getDefaultState = (): IApiKeyState => {
    return {
        apiKeys: [],
        showCreateModal: false,
        showCreateResultModal: false,
    };
};

export const apiKeys = createReducer(getDefaultState(), (builder) => {
    builder.addCase(API_KEYS_SHOW_MODAL, (state) => {
        state.showCreateModal = true;
    });
    builder.addCase(API_KEYS_HIDE_MODAL, (state) => {
        state.showCreateModal = false;
    });
    builder.addCase(API_KEYS_HIDE_RESULT_MODAL, (state) => {
        state.showCreateResultModal = false;
        state.createdApiKey = null;
    });
    builder.addCase(getApiKeys.success, (state, action) => {
        state.apiKeys = action.payload.keys;
        state.showCreateModal = false;
    });
    builder.addCase(deleteApiKey.success, (state, action) => {
        state.apiKeys = action.payload.keys;
    });
    builder.addCase(createApiKey.success, (state, action) => {
        state.showCreateModal = false;
        state.showCreateResultModal = true;
        state.createdApiKey = action.payload.apiKey;
    });
});
