import WebSocket from "reconnecting-websocket";
import { v4 } from "uuid";
import { API_BASE_URL } from "../components/utilities/ApiUtils";

const urlToken: string = v4();

const getWebsocketUri = (path: string) => {
    const loc: URL = API_BASE_URL
        ? new URL(API_BASE_URL)
        : new URL(window?.location?.href);

    if (loc) {
        let scheme: string;
        if (loc.protocol === "https:") {
            scheme = "wss";
        } else {
            scheme = "ws";
        }

        return `${scheme}://${loc.host}${path}/${urlToken}`;
    }

    return undefined;
};

const uri = getWebsocketUri("/websocket/pocketbook");

export const ws = uri && new WebSocket(uri);

export const send = (message: string) => {
    return ws.send(message);
};
