import { createAction } from "@reduxjs/toolkit";
import { Action } from "redux";
import { createRsaaAction } from "../../components/utilities/ReduxUtils";
import { IChemicalModel } from "../../data/ChemicalModel";
import { createSocketAction } from "../middlewares/websocket";
import {
    IEstimateCompoundsResponse,
    ILoadCompoundsRequest,
    ILoadCompoundsResponse,
    ISearchCompoundsRequest,
    ISearchCompoundsResponse,
} from "../reducers/pocketbook";

export const ADD_COMPOUND: string = "ADD_COMPOUND";
export const REMOVE_COMPOUND: string = "REMOVE_COMPOUND";
export const UPDATE_COMPOUND: string = "UPDATE_COMPOUND";
export const REMOVE_COMPOUNDS: string = "REMOVE_COMPOUNDS";
export const REPLACE_COMPOUNDS: string = "REPLACE_COMPOUNDS";
export const SEARCH_COMPOUNDS: string = "SEARCH_COMPOUNDS";
export const LOAD_COMPOUNDS: string = "LOAD_COMPOUNDS";
export const ESTIMATE_COMPOUND: string = "ESTIMATE_COMPOUND";
export const LOAD_SAVED_SEARCHES: string = "LOAD_SAVED_SEARCHES";
export const SAVE_CURRENT_SEARCH: string = "SAVE_CURRENT_SEARCH";
export const SET_PROPERTIES_TAB: string = "SET_PROPERTIES_TAB";
export const SET_GRAPH_TAB: string = "SET_GRAPH_TAB";

export const searchCompounds = createRsaaAction<
    ISearchCompoundsRequest,
    ISearchCompoundsResponse,
    ISearchCompoundsRequest
>({
    path: "chemicals/search",
    method: "POST",
    type: SEARCH_COMPOUNDS,
});

export const loadCompounds = createRsaaAction<
    ILoadCompoundsRequest,
    ILoadCompoundsResponse,
    ILoadCompoundsResponse
>({
    path: "chemicals/data",
    method: "POST",
    type: LOAD_COMPOUNDS,
    meta: (p) => ({
        clearCompounds: p.clearCompounds,
    }),
});

export interface IEstimatePayload {
    compoundId?: number;
    guid?: string;
    smiles: string;
    clearCompounds: boolean;
    useCache?: boolean;
    tasks?: string[];
}

export const estimateCompound = createSocketAction<
    IEstimatePayload,
    IEstimateCompoundsResponse,
    IEstimateCompoundsResponse
>({
    type: ESTIMATE_COMPOUND,
});

export const addCompound = createAction<IChemicalModel>(ADD_COMPOUND);

export const removeCompound = createAction<number>(REMOVE_COMPOUND);

export const updateCompound = createAction(UPDATE_COMPOUND);

export const removeCompounds = createAction(REMOVE_COMPOUNDS);

export const replaceCompounds =
    createAction<IChemicalModel[]>(REPLACE_COMPOUNDS);

export const loadSavedSearches = createRsaaAction<
    void,
    ISearchCompoundsResponse
>({
    path: "listSearches",
    method: "POST",
    type: LOAD_SAVED_SEARCHES,
});

export interface ISaveCurrentSearchRequest {
    compound_ids: number[];
}

export const saveCurrentSearch = createRsaaAction<ISaveCurrentSearchRequest>({
    path: "saveSearch",
    method: "POST",
    type: SAVE_CURRENT_SEARCH,
});

export const setPropertiesTab = createAction<number | string>(
    SET_PROPERTIES_TAB
);

export const getGraphs = (): GraphContextAction[] => [
    {
        key: "ghs_radar",
        title: "GHS Analysis",
        type: "radar",
        source: "ghs",
        display: true,
    },
    {
        key: "viridischem_radar",
        title: "ViridisChem Analysis",
        type: "radar",
        source: "viridischem",
        default: true,
        display: true,
    },
    // {
    //     key: "viridischem_bar",
    //     title: "Score Compare",
    //     type: "bar",
    //     source: "viridischem",
    //     display: false,
    // },
];

export interface GraphContextAction extends Action<string> {
    key: string;
    default?: boolean;
    display?: boolean;
    title: string;
    type: string;
    source: string;
}

export const setGraphContext = createAction<GraphContextAction>(SET_GRAPH_TAB);
