import React, { Suspense } from "react";
import { Route, Routes } from "react-router";
import { useAppSelector } from "../../redux/hooks";
import { IUserState } from "../../redux/types/IUserState";
const ApiKeys = React.lazy(() => import("../apiKeys/ApiKeys"));
const MyAccount = React.lazy(() => import("../account/MyAccount"));
const Dashboard = React.lazy(() => import("../dashboard/Dashboard"));
const Licenses = React.lazy(() => import("../licenses/Licenses"));
const ViridisLogin = React.lazy(() => import("../login/ViridisLogin"));
const Pocketbook = React.lazy(() => import("../pocketbook/Pocketbook"));
const ChemPredictor = React.lazy(
    () => import("../chempredictor/ChemPredictor")
);
const Agent = React.lazy(() => import("../agent/Agent"));
const ReactionAnalyzer = React.lazy(
    () => import("../reaction-analyzer/ReactionAnalyzer")
);
const UserAdmin = React.lazy(() => import("../userAdmin/UserAdmin"));
const GlobalHeader = React.lazy(() => import("./GlobalHeader"));

const AppWithHeader = (App) => () => (
    <div>
        <Suspense fallback={<div />}>
            <GlobalHeader />
        </Suspense>
        <Suspense fallback={<div />}>
            <App />
        </Suspense>
    </div>
);

export const ViridisApp = () => {
    const user: IUserState = useAppSelector((state) => state.user);

    if (!user || !user.userName) {
        return (
            <div className="whiteBG">
                <Suspense fallback={<div />}>
                    <ViridisLogin createAccount />
                </Suspense>
            </div>
        );
    } else {
        return (
            <div>
                <Routes>
                    <Route index Component={AppWithHeader(Dashboard)} />
                    <Route path="chemicals">
                        <Route index Component={AppWithHeader(Pocketbook)} />
                        <Route
                            path=":chemicalId"
                            Component={AppWithHeader(Pocketbook)}
                        />
                    </Route>
                    <Route
                        path="reactions"
                        Component={AppWithHeader(ReactionAnalyzer)}
                    />
                    <Route
                        path="chempredictor"
                        Component={AppWithHeader(ChemPredictor)}
                    />
                    <Route path="agent" Component={AppWithHeader(Agent)} />
                    <Route
                        path="licenses"
                        Component={AppWithHeader(Licenses)}
                    />
                    <Route path="users" Component={AppWithHeader(UserAdmin)} />
                    <Route
                        path="myaccount"
                        Component={AppWithHeader(MyAccount)}
                    />
                    <Route path="apiKeys" Component={AppWithHeader(ApiKeys)} />
                </Routes>
            </div>
        );
    }
};
