import { createSocketAction } from "../middlewares/websocket";

export const LLM_CONVERSATION_START = "LLM_CONVERSATION_START";
export const LLM_CONVERSATION_STOP = "LLM_CONVERSATION_STOP";
export const LLM_CONVERSATION_MESSAGE = "LLM_CONVERSATION_MESSAGE";
export const LLM_CONVERSATION_MESSAGE_STREAM =
    "LLM_CONVERSATION_MESSAGE_STREAM";

export interface IAgentConversationStartResponse {
    conversation_id: string;
}

export interface IAgentConversationRequest {
    message: IAgentMessage;
}

export interface IAgentMessage {
    conversation_id?: string;
    stream_id?: string;
    message_id: string;
    message_index?: number;
    token_index?: number;
    role?: string;
    content?: string;
}

export const agentConversationStart = createSocketAction<
    void,
    IAgentConversationStartResponse
>({
    type: LLM_CONVERSATION_START,
});

export const agentConversationStop = createSocketAction({
    type: LLM_CONVERSATION_STOP,
});

export const agentConversationSend = createSocketAction<
    IAgentConversationRequest,
    IAgentMessage
>({
    type: LLM_CONVERSATION_MESSAGE,
});

export const agentConversationStream = createSocketAction<
    IAgentConversationRequest,
    IAgentMessage
>({
    type: LLM_CONVERSATION_MESSAGE_STREAM,
});
