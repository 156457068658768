import { PayloadAction, createReducer } from "@reduxjs/toolkit";
import {
    IAgentMessage,
    agentConversationSend,
    agentConversationStart,
    agentConversationStop,
    agentConversationStream,
} from "../actions/agent";

export interface IAgentState {
    conversation_id?: string;
    message_ids: string[];
    messages: { [key: string]: IAgentMessage };
}

const getDefaultState = (): IAgentState => {
    return {
        message_ids: [],
        messages: {},
    };
};

const mergeMessage = (
    state: IAgentState,
    action: PayloadAction<IAgentMessage>,
    append: boolean = false
) => {
    const messageId = action.payload.message_id;
    if (messageId in state.messages) {
        if (append) {
            state.messages[messageId].content =
                state.messages[messageId].content + action.payload.content;
        } else {
            state.messages[messageId] = action.payload;
        }
    } else {
        state.message_ids.push(messageId);
        state.messages[messageId] = action.payload;
    }
};

export const agent = createReducer(getDefaultState(), (builder) => {
    builder.addCase(agentConversationStart.success, (state, action) => {
        state.conversation_id = action.payload.conversation_id;
    });
    builder.addCase(agentConversationStop.success, () => {
        return getDefaultState();
    });
    builder.addCase(agentConversationSend.request, (state, action) => {
        mergeMessage(state, action);
    });
    builder.addCase(agentConversationSend.success, (state, action) => {
        mergeMessage(state, action);
    });
    builder.addCase(agentConversationStream.request, (state, action) => {
        mergeMessage(state, action);
    });
    builder.addCase(agentConversationStream.update, (state, action) => {
        mergeMessage(state, action, true);
    });
    builder.addCase(agentConversationStream.success, (state, action) => {
        mergeMessage(state, action, true);
    });
});
