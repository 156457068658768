import { Action, PayloadActionCreator, createAction } from "@reduxjs/toolkit";
import { RSAA } from "redux-api-middleware";
import { IApiError } from "../../redux/fetch";
import { getApiPath, pathToUrl } from "./ApiUtils";

export const RSAA_SYMBOL: unique symbol = RSAA;

export type IRsaaActionMethodType = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

export interface IRsaaActionArguments<P> {
    path: string;
    method: IRsaaActionMethodType;
    type: string;
    type_request: string;
    type_success: string;
    type_error: string;
    meta?: (payload?: P) => IRsaaMeta;
    cache?: boolean;
    cache_key?: any;
}

export interface IRsaaActionExtArguments<P> {
    path: string;
    method: IRsaaActionMethodType;
    meta?: (payload?: P) => IRsaaMeta;
    type: string;
    cache?: boolean;
    cache_key?: any;
}

export interface IRsaaMeta {
    cache?: boolean;
    cache_key?: string;
    [key: string]: any;
}

export interface IRsaaActionType {
    type: string;
    meta?: IRsaaMeta;
}

interface IRsaaActionParams<P> {
    endpoint: string;
    credentials?: string;
    fetch?: (url: string, init?: RequestInit) => Promise<Response>;
    method: IRsaaActionMethodType;
    body?: P;
    types: string[] | IRsaaActionType[];
}

export type IRsaaActionCreator<P> = (
    params?: ICreateRsaaActionArgs<P>
) => IRsaaAction<P>;

export interface IRsaaAction<P> extends Action {
    [RSAA_SYMBOL]: IRsaaActionParams<P>;
    meta: IRsaaMeta;
}

export interface ICreateRsaaActionArgs<P> {
    body?: P;
    pathParams?: any;
}

/**
 * Generate action creator for RSAA action.
 * @typeParam P - The type of the payload body for a POST
 * @param args - The action arguments
 * @returns Generated action for the network call
 * @public
 */
const _createRsaaAction =
    <P = void>(args: IRsaaActionArguments<P>): IRsaaActionCreator<P> =>
    (actionArgs: ICreateRsaaActionArgs<P>): IRsaaAction<P> => {
        const meta = args?.meta ? args.meta(actionArgs.body) : {};

        return {
            type: args.type,
            [RSAA_SYMBOL]: {
                endpoint: getApiPath(
                    pathToUrl(args.path, actionArgs?.pathParams)
                ),
                method: args.method ?? "POST",
                body: actionArgs?.body,
                types: [args.type_request, args.type_success, args.type_error],
            },
            meta: {
                cache: args.cache,
                cache_key: args.cache_key,
                ...meta,
            },
        };
    };

export interface ICreateRsaaAction<P, S, E> {
    action: IRsaaActionCreator<P>;

    request: PayloadActionCreator<void, string>;
    success: PayloadActionCreator<S, string>;
    error: PayloadActionCreator<E, string>;

    type_request: string;
    type_success: string;
    type_error: string;
}

/**
 * Generate action creators for RSAA action with actions for handling
 * REQUEST/SUCCESS/ERROR subsequent actions.
 * @typeParam P - The type of the payload body for a POST
 * @typeParam S - The type of the payload the 'success' reducer accepts
 * @typeParam E - The type of the payload the 'error' reducer accepts
 * @param args - The action arguments
 * @returns Generated actions for the network call and for handling redux callbacks
 * @public
 */
export const createRsaaAction = <P = void, S = void, E = IRsaaApiError<string>>(
    args: IRsaaActionExtArguments<P>
): ICreateRsaaAction<P, S, E> => {
    const type_request = `${args.type}_REQUEST`;
    const type_success = `${args.type}_SUCCESS`;
    const type_error = `${args.type}_ERROR`;

    return {
        action: _createRsaaAction<P>({
            ...args,
            type_request,
            type_success,
            type_error,
        }),

        request: createAction(type_request),
        success: createAction<S>(type_success),
        error: createAction<E>(type_error),

        type_request,
        type_success,
        type_error,
    };
};

export interface IRsaaApiError<T> {
    error: boolean;
    response: IApiError<T>;
}

export interface IRsaaApiErrorAction<T> extends Action<string> {
    payload: IRsaaApiError<T>;
}
